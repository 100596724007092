module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/@petlabco/builder-checkout-integration-plugin/gatsby-browser.js'),
      options: {"plugins":[],"builderApiKey":"e9ed5fb489ab4b769611bc610a472a56","funnelApiUrl":"https://cdn.builder.io/api/v3/content/funnel","productSelectorApiUrl":"https://cdn.builder.io/api/v3/content/product-selector","productVariantApiUrl":"https://cdn.builder.io/api/v3/content/product-variant","salesLetterApiUrl":"https://cdn.builder.io/api/v3/content/sales-letter","quizApiUrl":"https://cdn.builder.io/api/v3/content/quiz","slideApiUrl":"https://cdn.builder.io/api/v3/content/slide","directToCheckoutApiUrl":"https://cdn.builder.io/api/v3/content/direct-to-checkout","checkoutApiUrl":"https://checkout.thepetlabco.com","checkoutStoreName":"balance-probiotic-skincare.myshopify.com","checkoutApiKey":"6620b2ed-1a5c-41a0-9ffc-264d24bf34f1","limit":100,"chunk":"1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5XDGQT2","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MMRJ7684","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
