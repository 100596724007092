exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-banners-preview-js": () => import("./../../../src/pages/banners-preview.js" /* webpackChunkName: "component---src-pages-banners-preview-js" */),
  "component---src-pages-funnel-preview-js": () => import("./../../../src/pages/funnel-preview.js" /* webpackChunkName: "component---src-pages-funnel-preview-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-funnel-jsx": () => import("./../../../src/templates/Funnel.jsx" /* webpackChunkName: "component---src-templates-funnel-jsx" */),
  "component---src-templates-quiz-jsx": () => import("./../../../src/templates/Quiz.jsx" /* webpackChunkName: "component---src-templates-quiz-jsx" */),
  "component---src-templates-redirect-jsx": () => import("./../../../src/templates/Redirect.jsx" /* webpackChunkName: "component---src-templates-redirect-jsx" */),
  "component---src-templates-sales-letter-jsx": () => import("./../../../src/templates/SalesLetter.jsx" /* webpackChunkName: "component---src-templates-sales-letter-jsx" */),
  "component---src-templates-slide-jsx": () => import("./../../../src/templates/Slide.jsx" /* webpackChunkName: "component---src-templates-slide-jsx" */)
}

